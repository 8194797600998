import {Link} from 'gatsby';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Calendar as CalendarIcon, MapPin as MapPinIcon} from 'react-feather';

import {breakpointsMediaQueries, COLORS} from '../../style/my-theme';

import {ContainerLg} from '../components';

const StyledHeroSection = styled.section.attrs({
  className: 'hero-section'
})`
  position: relative;
  height: calc(100vh - 64px);
  background: transparent linear-gradient(180deg, #0f0f2000 0%, #0f0f20 100%) 0% 0% no-repeat
    padding-box;

  .container-lg {
    height: 100%;
    z-index: 1;

    ${breakpointsMediaQueries.md} {
      display: flex;
      .hero-logo {
        flex-direction: column;
      }
      .hero-video {
        display: none;
      }
    }

    .hero-info-wrapper {
      position: relative;

      .hero-logo {
        display: flex;
        width: 100%;
        //max-width: 580px;
        flex-direction: row;
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        color: ${COLORS.white};

        ${breakpointsMediaQueries.md} {
          left: initial;
          color: ${COLORS.primary};
        }
        .hero-text-wrapper {
          display: flex;
          flex-direction: column;

          .title {
            display: flex;
            flex-direction: column;
            font-size: 80px;
            font-weight: 700;
            line-height: 80px;

            .yellow {
              color: ${COLORS.yellow};
            }

            ${breakpointsMediaQueries.md} {
              margin-left: 0;
              font-size: 2.5rem;
              line-height: 3rem;
              color: ${COLORS.white};
            }
          }
          .subtitle {
            display: flex;
            flex-direction: column;
            font-size: 40px;

            ${breakpointsMediaQueries.md} {
              font-size: 1.6rem;
              margin-top: 1rem;
              color: ${COLORS.white};
            }

            .icon-wrapper {
              display: flex;
              align-items: center;
              gap: 1rem;
            }

            .btn-ticket {
              background: ${COLORS.yellow};
              color: ${COLORS.primary};
              border: none;
              box-shadow: 0px 5px 0px ${COLORS.primary};
              border-radius: 0.5rem;
              padding: 0 28px;
              height: 44px;
              line-height: 44px;
              font-size: 1.2rem;
              font-weight: 600;
              outline: none;
              display: none;

              &:hover {
                background: ${COLORS.yellowLight};
                cursor: pointer;
              }
              ${breakpointsMediaQueries.lg} {
                display: initial;
              }
              ${breakpointsMediaQueries.md} {
                margin: 2rem 0;
                width: auto;
                height: 3rem;
              }
            }
          }
        }

        .ticket-container {
          color: ${COLORS.armadaDark};
          height: 424px;
          width: 300px;
          border-radius: 8px;
          margin-left: auto;
          background: rgb(27, 171, 200);
          background: linear-gradient(126deg, rgba(27, 171, 200, 1) 0%, rgba(246, 223, 25, 1) 100%);
          ${breakpointsMediaQueries.lg} {
            display: none;
          }

          &:hover {
            cursor: pointer;
            background: rgb(27, 171, 200);
            background: linear-gradient(
              325deg,
              rgba(27, 171, 200, 1) 0%,
              rgba(246, 223, 25, 1) 100%
            );
            .bottom-container {
              .btn-ticket {
                span {
                  transform: translate3d(-1rem, 0, 0);
                }
                svg {
                  opacity: 1;
                  right: -2rem;
                }
              }
            }
          }
          .top-container {
            height: 30%;
            font-size: 40px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 2px dashed ${COLORS.armadaDark};
          }
          .bottom-container {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-top: 42px;

            .price {
              font-size: 60px;
              font-weight: 700;
            }
            .terms {
              font-size: 20px;
            }
            .btn-ticket {
              cursor: pointer;
              width: 244px;
              height: 48px;
              border: 2px solid ${COLORS.armadaDark};
              border-radius: 4px;
              background: transparent;
              align-self: center;
              font-size: 20px;
              font-weight: 700;
              margin-top: 48px;
              position: relative;
              transition: background-color 300ms ease-out;
              span {
                display: inline-block;
                position: relative;
                transition: all 300ms ease-out;
                will-change: transform;
              }
              svg {
                position: absolute;
                width: 1.1em;
                right: 0px;
                right: 0rem;
                opacity: 0;
                top: 50%;
                transform: translateY(-50%);
                transition: all 300ms ease-out;
                will-change: right, opacity;
                * {
                  stroke-width: 5;
                  stroke-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }

  .hero-video {
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    ${breakpointsMediaQueries.md} {
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      height: 100%;
      width: 100%;
      background-color: ${COLORS.primary};
      opacity: 0.4;
    }

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`;

const HeroSection = () => {
  const [videoSource, setVideoSource] = useState<JSX.Element | null>(null);
  const [autoPlay, setAutoPlay] = useState(false);

  const DecorVideoSource = () => (
    <>
      <source src={process.env.HERO_VIDEO_WEBM_URL} type="video/webm" />
      <source src={process.env.HERO_VIDEO_MP4_URL} type="video/mp4" />
    </>
  );

  useEffect(() => {
    const connection = (navigator as any).connection || {effectiveType: '4g'};
    const isFastNetwork = !/[23]g/.test((connection as any).effectiveType);

    const allowMotion = !matchMedia('(prefers-reduced-motion)').matches;

    if (isFastNetwork) {
      setVideoSource(<DecorVideoSource />);
      setAutoPlay(allowMotion);
    }
  }, []);

  const DecorVideo = ({source, autoPlay}: {source: JSX.Element | null; autoPlay: boolean}) => (
    <video autoPlay={autoPlay} loop muted playsInline className="hero-content">
      {source}
    </video>
  );
  return (
    <StyledHeroSection>
      <div className="hero-video">
        <DecorVideo source={videoSource} autoPlay={autoPlay} />
      </div>
      <ContainerLg>
        <div className="hero-info-wrapper">
          <div className="hero-logo">
            <div className="hero-text-wrapper">
              <div className="title">
                <span>FULL-STACK</span>
                <span className="yellow">JAVASCRIPT</span>
                <span>CONFERENCE</span>
              </div>
              <div className="subtitle">
                <span className="icon-wrapper">
                  <MapPinIcon color={COLORS.blueLight} width={36} height={36} />
                  Novi Sad, Serbia
                </span>
                <span className="icon-wrapper">
                  <CalendarIcon color={COLORS.blueLight} width={36} height={36} />5 - 6 December,
                  2024
                </span>
                {/* <a href="/armada-schedule-2023-v2.pdf">
                  <button className="btn-ticket">SCHEDULE</button>
                </a> */}
                <Link to={'/tickets'}>
                  <button className="btn-ticket">GET YOUR TICKET</button>
                </Link>
              </div>
            </div>
            <Link style={{marginLeft: 'auto'}} to={'/tickets'}>
              <div className="ticket-container">
                <div className="top-container">TICKETS</div>
                <div className="bottom-container">
                  <span className="price">&#8364; 280</span>
                  <span className="terms">
                    <sup>*</sup>Until November, 25<sup>th</sup>
                  </span>

                  <button className="btn-ticket">
                    <span>
                      Get Your Ticket
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 36.1 25.8"
                        enable-background="new 0 0 36.1 25.8"
                        xmlSpace="preserve"
                      >
                        <g>
                          <line
                            fill="none"
                            stroke={COLORS.armadaDark}
                            stroke-width="3"
                            stroke-miterlimit="10"
                            x1="0"
                            y1="12.9"
                            x2="34"
                            y2="12.9"
                          ></line>
                          <polyline
                            fill="none"
                            stroke={COLORS.armadaDark}
                            stroke-width="3"
                            stroke-miterlimit="10"
                            points="22.2,1.1 34,12.9 22.2,24.7   "
                          ></polyline>
                        </g>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </ContainerLg>
    </StyledHeroSection>
  );
};

export default HeroSection;
